
import { fetchSessionUser } from '@/api/methods';
import { AnalysisSet, DomainSet } from '@/api/models/analysis-set';
import { defaultBarPropSet, matchGraphColour } from '@/api/setings';
import { Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';


export default class ResultsBar extends Vue {
  @Prop({ default: () => new DomainSet() }) readonly domainSet: DomainSet = new DomainSet();

  domainKey = 'O';

  title = '';
  
  graphData = {
    labels: ['O', 'C', 'E', 'A', 'N'],
    datasets: [
      {
        ...defaultBarPropSet,
        data: [0, 0, 0, 0, 0]
      },
    ]
  }

  options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    },
    aspectRatio: 1.2
  }

  loaded = false;

  get user() {
    return fetchSessionUser();
  }

  created() {
    this.emitter.on('update-analysis', (sKey: string) => {
      if (sKey) {
        this.sync();
      }
    });
  }
  
  mounted() {
    setTimeout(this.init, 375);
  }

  init() {
    this.sync();
  }

  sync() {
    if (this.domainSet.facets.length > 0) {
      this.graphData.datasets = [];
      this.title = this.domainSet.title;
      const data: number[] = [];
      this.graphData.labels = this.domainSet.facets.map(fc => fc.title);
      this.domainSet.facets.forEach((fc, fi) => {
        data.push(fc.pc);
      })
      const backgroundColor = data.map((_,di) => matchGraphColour(di));
      const dataSet = {...defaultBarPropSet, backgroundColor, data };
      this.graphData.datasets.push(dataSet);
    }
    this.loaded = true;  
  }

  @Watch('analysisSets')
  changeAnalysisSets(newVal: AnalysisSet[] = []) {
    if (newVal instanceof Array) {
      this.init();
    }
  }
  
}
