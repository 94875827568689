import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "results-section-wrapper jungian" }
const _hoisted_2 = { class: "graph-wrapper octagonal" }
const _hoisted_3 = { class: "chart-overlay" }
const _hoisted_4 = { class: "inner" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "score-display" }
const _hoisted_7 = { class: "feedback-container" }
const _hoisted_8 = { class: "subtitle domain-title" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "score-display" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Chart, {
        type: "radar",
        data: _ctx.graphData,
        options: _ctx.options,
        class: "octagon"
      }, null, 8, ["data", "options"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (polSet) => {
          return (_openBlock(), _createElementBlock("h5", {
            key: ['polarity-label', polSet.key].join('-'),
            class: _normalizeClass(["domain-label poled", polSet.key])
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createElementVNode("span", {
                class: "text",
                innerHTML: polSet.poleLabel
              }, null, 8, _hoisted_5),
              _createElementVNode("span", _hoisted_6, _toDisplayString(polSet.pcPoledStr3), 1)
            ])
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.poleLetters), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (polSet) => {
        return (_openBlock(), _createElementBlock("div", {
          key: ['polarity-fb', polSet.key].join('-'),
          class: "all"
        }, [
          _createElementVNode("h4", _hoisted_8, [
            _createElementVNode("span", {
              class: "text",
              innerHTML: polSet.poleLabel
            }, null, 8, _hoisted_9),
            _createElementVNode("span", _hoisted_10, _toDisplayString(polSet.pcPoledStr), 1)
          ]),
          _createElementVNode("div", {
            class: "first",
            innerHTML: polSet.feedbackText()
          }, null, 8, _hoisted_11),
          _createElementVNode("div", {
            class: "second",
            innerHTML: polSet.feedbackText2()
          }, null, 8, _hoisted_12)
        ]))
      }), 128))
    ])
  ]))
}